'use strict';

import { IntentionFeeTypes } from '../../react/settings/models/fee';
import PortalService from '../../react/portal/services/portal.service';

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.private.settings', {
        abstract: true,
        url: '/settings',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
        resolve: {
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name,
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },

        onEnter: [
          '$state',
          '$rootScope',
          'gettextCatalog',
          '_',
          'previousState',
          function ($state, $rootScope, gettextCatalog, _, previousState) {
            let stateName = _.split(previousState.name, '.')[2];
            if (stateName === 'intranet') stateName = 'groups';

            const moduleName =
              (stateName !== 'settings' && stateName) || 'dashboard';

            const url =
              (stateName !== 'settings' && previousState.url) ||
              $state.href('app.private.dashboard.default');
            const name = gettextCatalog.getString(
              moduleName,
              null,
              'The name of the app'
            );

            if (stateName !== 'settings') {
              $rootScope.settingsModuleReferenceState = { name, url };
            }
          },
        ],
      })
      .state('app.private.settings.default', {
        url: '',
        component: 'cdSettings',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Settings');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.account', {
        url: '/account',
        component: 'cdAccountSettingsState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Account settings');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            if (!Authorization.hasPermission('canAdministerUsers')) {
              $state.go('app.public.errorState', {
                errorCode: 'ACCESS_DENIED',
              });

              return false;
            }
          },
        ],
      })
      .state('app.private.settings.roles', {
        url: '/roles',
        params: {
          forceReloadRoles: false,
        },

        component: 'cdManageRolesState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Roles and access');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.roles.edit', {
        url: '/:roleContext/:id/edit',
        component: 'cdManageRolesState',
        params: {
          forceReloadRoles: false,
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Roles and access');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.users', {
        abstract: true,
        url: '/users',
        template: '<div class="app-view" ui-view></div>',
      })
      .state('app.private.settings.users.list', {
        url: '?{firstName:string}&{lastName:string}&{email:string}',
        component: 'cdUsersSettingsState',
        params: {
          firstName: null,
          lastName: null,
          email: null,
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Users');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            if (!Authorization.hasPermission('canAdministerUsers')) {
              $state.go('app.public.errorState', {
                errorCode: 'ACCESS_DENIED',
              });

              return false;
            }
          },
        ],
      })
      .state('app.private.settings.users.edit', {
        url: '/:id/edit',
        templateUrl:
          '@/app/settings/users-settings/user-edit/user-edit.component.html',
        controller: 'EditController',
        resolve: {
          userData: [
            'Users',
            '$stateParams',
            function (Users, $stateParams) {
              return Users.get({ id: $stateParams.id }).$promise;
            },
          ],

          $title: [
            'Me',
            'gettextCatalog',
            'userData',
            'routingHelpers',
            function (Me, gettextCatalog, userData, routingHelpers) {
              const base = 'User Edit';

              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString('Edit {{user}}', {
                    user:
                      userData.name && userData.name !== ' '
                        ? userData.name
                        : userData.email,
                  })
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.users.edit.account', {
        url: '/account',
      })
      .state('app.private.settings.users.edit.basic', {
        url: '/basic',
      })
      .state('app.private.settings.users.edit.contact', {
        url: '/contact',
      })
      .state('app.private.settings.users.edit.notifications', {
        url: '/notification',
      })
      .state('app.private.settings.users.edit.administrator', {
        url: '/administrator',
      })
      .state('app.private.settings.users.edit.graveyard', {
        url: '/graveyard',
      })
      .state('app.private.settings.users.detail', {
        url: '/:id',
        templateUrl:
          '@/app/settings/users-settings/user-detail/user-detail.component.html',
        controller: 'ProfileController',
        resolve: {
          userData: [
            'Users',
            '$stateParams',
            function (Users, $stateParams) {
              return Users.get({ id: $stateParams.id }).$promise;
            },
          ],

          $title: [
            'userData',
            'routingHelpers',
            '$filter',
            function (userData, routingHelpers, $filter) {
              const base = 'User Detail';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  $filter('getName')(userData)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.calendar', {
        url: '/calender/:type',
        template: require('./taxonomy-list/taxonomy-list.component.html'),
        controller: 'TaxonomiesController',
        resolve: {
          $title: [
            'gettextCatalog',
            '$stateParams',
            'routingHelpers',
            function (gettextCatalog, $stateParams, routingHelpers) {
              switch ($stateParams.type) {
                case 'event': {
                  const base = 'Event categories';
                  return {
                    base,
                    rendered: routingHelpers.buildTitle(
                      gettextCatalog.getString(base)
                    ),
                  };
                }
                case 'blog': {
                  const base = 'Blog categories';
                  return {
                    base,
                    rendered: routingHelpers.buildTitle(
                      gettextCatalog.getString(base)
                    ),
                  };
                }
                case 'absence': {
                  const base = 'Absence types';
                  return {
                    base,
                    rendered: routingHelpers.buildTitle(
                      gettextCatalog.getString(base)
                    ),
                  };
                }
                default: {
                  const base = 'Taxonomies';
                  return {
                    base,
                    rendered: routingHelpers.buildTitle(
                      gettextCatalog.getString(base)
                    ),
                  };
                }
              }
            },
          ],
        },
        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            if (!Authorization.hasPackage('calendar')) {
              $state.go('app.private.settings.other');

              return false;
            }
          },
        ],
      })
      .state('app.private.settings.calendar.create', {
        url: '/create',
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          'Partnership',
          'cdApp',
          function ($stateParams, $state, $uibModal, Partnership, cdApp) {
            'ngInject';

            const type = $stateParams.type;

            $uibModal
              .open({
                component: 'cdTaxonomyDetailModal',
                windowClass: 'modal-ui-select',
                resolve: {
                  partners: () =>
                    type === 'event'
                      ? Partnership.categories.query().$promise
                      : null,
                  organizationPortals: () =>
                    type === 'event' || type === 'blog'
                      ? PortalService.getOrganizationPortals({
                          organizationId: cdApp.organization.id,
                        })
                      : null,
                  type: () => type,
                  map: () => null,
                  portalCategoryMap: () => null,
                  data: () => null,
                  churches: () => [],
                },
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.settings.calendar.edit', {
        url: '/edit/:id',
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          'Resources',
          'Taxonomies',
          'Partnership',
          'cdApp',
          function (
            $stateParams,
            $state,
            $uibModal,
            Resources,
            Taxonomies,
            Partnership,
            cdApp
          ) {
            'ngInject';

            const type = $stateParams.type;
            $uibModal
              .open({
                component: 'cdTaxonomyDetailModal',
                windowClass: 'modal-ui-select',
                resolve: {
                  type: () => type,
                  partners: () =>
                    type === 'event'
                      ? Partnership.categories.query().$promise
                      : null,
                  organizationPortals: () =>
                    type === 'event' || type === 'blog'
                      ? PortalService.getOrganizationPortals({
                          organizationId: cdApp.organization.id,
                        })
                      : null,
                  map: () =>
                    type === 'event'
                      ? Partnership.map.query({ categoryId: $stateParams.id })
                          .$promise
                      : null,
                  portalCategoryMap: () =>
                    type === 'event' || type === 'blog'
                      ? PortalService.getCategoryMapping($stateParams.id)
                      : null,
                  data() {
                    const TaxonomyResource =
                      type === 'resource' || type === 'church'
                        ? Resources
                        : Taxonomies;
                    return TaxonomyResource.get({ id: $stateParams.id })
                      .$promise;
                  },
                  churches: () =>
                    type === 'resource'
                      ? Resources.query().$promise.then((resources) => {
                          const churches = _.filter(
                            resources,
                            (resource) => resource.type === 'church'
                          );

                          return _.orderBy(churches, ['name'], ['asc']);
                        })
                      : [],
                },
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.settings.calendar.delete', {
        url: '/delete/:id',
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          'Resources',
          'Taxonomies',
          function ($stateParams, $state, $uibModal, Resources, Taxonomies) {
            'ngInject';

            const type = $stateParams.type;
            $uibModal
              .open({
                component: 'cdTaxonomyDeleteModal',
                windowClass: 'modal-ui-select',
                resolve: {
                  type: () => type,
                  data() {
                    const TaxonomyResource =
                      type === 'resource' ? Resources : Taxonomies;
                    return TaxonomyResource.get({ id: $stateParams.id })
                      .$promise;
                  },
                  replacementTaxonomies: () => {
                    if (type === 'resource') return Resources.query().$promise;
                    if (
                      type === 'event' ||
                      type === 'absence' ||
                      type === 'blog'
                    ) {
                      return Taxonomies.query().$promise;
                    }
                    return [];
                  },
                },
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.settings.resources', {
        url: '/resources',
        template: require('./resource-list/resource-list.component.html'),
        controller: 'ResourcesController',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Parish & Resources');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.resources.create', {
        url: '/:type/create',
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          'Partnership',
          'Resources',
          'cdApp',
          function (
            $stateParams,
            $state,
            $uibModal,
            Partnership,
            Resources,
            cdApp
          ) {
            'ngInject';

            const type = $stateParams.type;
            $uibModal
              .open({
                component: 'cdTaxonomyDetailModal',
                windowClass: 'modal-ui-select',
                resolve: {
                  partners: () =>
                    type === 'event'
                      ? Partnership.categories.query().$promise
                      : null,
                  organizationPortals: () =>
                    type === 'event'
                      ? PortalService.getOrganizationPortals({
                          organizationId: cdApp.organization.id,
                        })
                      : null,
                  type: () => type,
                  map: () => null,
                  portalCategoryMap: () => null,
                  data: () => null,
                  churches: () =>
                    type === 'resource'
                      ? Resources.query().$promise.then((resources) => {
                          const churches = _.filter(
                            resources,
                            (resource) => resource.type === 'church'
                          );

                          return _.orderBy(churches, ['name'], ['asc']);
                        })
                      : [],
                },
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.settings.resources.edit', {
        url: '/:type/edit/{id:int}',
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          'Resources',
          function ($stateParams, $state, $uibModal, Resources) {
            'ngInject';
            const type = $stateParams.type;
            $uibModal
              .open({
                component: 'cdTaxonomyDetailModal',
                windowClass: 'modal-ui-select',
                resolve: {
                  type: () => type,
                  partners: () => null,
                  map: () => null,
                  portalCategoryMap: () => null,
                  data() {
                    return Resources.get({ id: $stateParams.id }).$promise;
                  },
                  churches: () =>
                    type === 'resource'
                      ? Resources.query().$promise.then((resources) => {
                          const churches = _.filter(
                            resources,
                            (resource) => resource.type === 'church'
                          );

                          return _.orderBy(churches, ['name'], ['asc']);
                        })
                      : [],
                },
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.settings.resources.delete', {
        url: '/delete/:id',
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          'Resources',
          'Taxonomies',
          function ($stateParams, $state, $uibModal, Resources, Taxonomies) {
            'ngInject';

            const type = 'resource';
            $uibModal
              .open({
                component: 'cdTaxonomyDeleteModal',
                windowClass: 'modal-ui-select',
                resolve: {
                  type: () => type,
                  data() {
                    const TaxonomyResource =
                      type === 'resource' ? Resources : Taxonomies;
                    return TaxonomyResource.get({ id: $stateParams.id })
                      .$promise;
                  },
                  replacementTaxonomies: () => {
                    if (type === 'resource') return Resources.query().$promise;
                    if (type === 'event' || type === 'absence') {
                      return Taxonomies.query().$promise;
                    }
                    return [];
                  },
                },
              })
              .result.finally(() => $state.go('^'));
          },
        ],
      })
      .state('app.private.settings.people', {
        url: '/people',
        component: 'cdrPeopleSettings',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('People settings');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          churchInfo: [
            'Church',
            function (Church) {
              return Church.get().$promise;
            },
          ],
        },
        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            if (!Authorization.hasPackage('people')) {
              $state.go('app.private.settings.other');

              return false;
            }
          },
        ],
      })
      .state('app.private.settings.widgets', {
        url: '/widgets',
        component: 'cdWidgetsSettingsState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Widgets');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.legal', {
        url: '/legal',
        component: 'cdLegalSettingsState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Legal');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.other', {
        url: '/other',
        component: 'cdOtherSettingsState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Other settings');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.mvrForm', {
        url: '/mvr',
        component: 'cdContributionsMvrState',
        params: {
          accountData: null,
          stripeAccount: null,
        },

        lazyLoad: ($transition$) => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

          return import(
            /* webpackChunkName: "contributions.module" */ '@/app/contributions/contributions.module'
          )
            .then((mod) => $ocLazyLoad.load(mod.default))
            .catch((err) => console.error(err));
        },
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Minimum Verification Requirements');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          accountData: [
            '$stateParams',
            '$rootScope',
            function ($stateParams, $rootScope) {
              return $stateParams.accountData || $rootScope.accountData;
            },
          ],

          stripeAccount: [
            '$stateParams',
            'StripeAccount',
            function ($stateParams, StripeAccount) {
              return (
                $stateParams.stripeAccount ||
                StripeAccount.getAccount({ id: cdApp.organization.id }).$promise
              );
            },
          ],
        },

        onEnter: [
          '$state',
          '$location',
          'accountData',
          'Authorization',
          function ($state, $location, accountData, Authorization) {
            if (Authorization.hasPermission('canAccessContributions')) {
              if (accountData.id) return true;
              $state.target('app.private.contributions.projects.list');
            } else {
              $state.target('app.private.contributions.default');
            }
            return false;
          },
        ],
      })

      // Fees
      .state('app.private.settings.fees', {
        abstract: true,
        url: '/fees',
        template: `
              <div class="app-view" ui-view></div>
            `,
      })
      .state('app.private.settings.fees.overview', {
        url: '/?{tab:string}',
        component: 'cdrIntentionFees',
        params: {
          tab: {
            value: IntentionFeeTypes.INTENTIONEN,
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Fees');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.fees.create', {
        url: '/create',
        component: 'cdrCreateIntentionFee',
        params: {
          currentState: {
            value: 'create',
            dynamic: true,
            squash: true,
          },

          type: {
            value: IntentionFeeTypes.INTENTIONEN,
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Fees');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.fees.edit', {
        url: '/edit/:id?{currentState}',
        component: 'cdrCreateIntentionFee',
        params: {
          currentState: {
            value: 'edit',
            dynamic: true,
            squash: true,
          },

          type: {
            value: IntentionFeeTypes.INTENTIONEN,
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Fees');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      // Report templates
      .state('app.private.settings.reportTemplates', {
        abstract: true,
        url: '/report-templates',
        template: `
              <div class="app-view" ui-view></div>
            `,
      })
      .state('app.private.settings.reportTemplates.overview', {
        url: '/',
        component: 'cdReportTemplatesOverview',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Report templates');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.reportTemplates.create', {
        url: '/create',
        component: 'cdCreateReportTemplate',
        params: {
          currentState: {
            value: 'create',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Report templates');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.reportTemplates.edit', {
        url: '/edit/:id?{currentState}',
        component: 'cdCreateReportTemplate',
        params: {
          currentState: {
            value: 'edit',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Report templates');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.privacyPolicy', {
        url: '/privacy-policy',
        component: 'cdrPrivacyPolicy',
        params: {},

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Privacy policy');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.settings.imprint', {
        url: '/imprint',
        component: 'cdrImprint',
        params: {},

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Imprint');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      });
  },
]);
