import React from 'react';
import { Space, Typography } from 'antd';

import gettextCatalog from '../../services/I18nService';

export enum fileUploadTypes {
  CERTIFICATE_OF_CONDUCT = 'certificateOfConduct',
  DOCUMENT = 'document',
  COURSE = 'course',
}
export const safeguardingRoles = () => [
  { value: 'employee', label: gettextCatalog.getString('Employee') },
  { value: 'volunteer', label: gettextCatalog.getString('Volunteer') },
];

export interface ISafeguardingLevelCheckType {
  value: string;
  heading: string;
  description?: string;
}

export const safeguardingLevelChecks = (): ISafeguardingLevelCheckType[] => [
  {
    value: 'not required',
    heading: gettextCatalog.getString('Not required'),
    description: gettextCatalog.getString(
      'There are no safeguarding requirements to this person and the role they perform.'
    ),
  },
  {
    value: 'verified by third party',
    heading: gettextCatalog.getString('Verified by third party'),
    description: gettextCatalog.getString(
      'The safeguarding requirements are already carried out by a trusted external organization, independent of ChurchDesk.'
    ),
  },
  {
    value: 'minimum',
    heading: gettextCatalog.getString('Minimum'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement and code of conduct.'
    ),
  },
  {
    value: 'basic',
    heading: gettextCatalog.getString('Basic'),
    description: gettextCatalog.getString(
      'For basic both the self-disclosure statement and code of conduct documents are required along with attending a basic introductory course to safeguarding.'
    ),
  },
  {
    value: 'basic plus',
    heading: gettextCatalog.getString('Basic plus'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement, code of conduct and the certificate of conduct for review. Furthermore an introduction course is necessary'
    ),
  },
  {
    value: 'extended',
    heading: gettextCatalog.getString('Extended'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement and Code of conduct as well as taking an extended course'
    ),
  },
  {
    value: 'extended plus',
    heading: gettextCatalog.getString('Extended plus'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement, code of conduct and the certificate of conduct for review. Furthermore an extended course is necessary'
    ),
  },
];

export const safeguardingLevelCheckToSelectOption = (
  level: ISafeguardingLevelCheckType
) => ({
  value: level.value,
  label: level.description ? (
    <Space direction="vertical">
      <Typography.Text>{level.heading}</Typography.Text>
      <Typography.Text
        type="secondary"
        style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
      >
        {level.description}
      </Typography.Text>
    </Space>
  ) : (
    level.heading
  ),
});

export const safeguardingTypeDropDown = () => [
  { value: 'all', label: gettextCatalog.getString('All') },
  { value: 'missing', label: gettextCatalog.getString('Missing') },
];
export const documentTypes = () => [
  {
    value: 'selfDisclosure',
    label: gettextCatalog.getString('Self-disclosure statement'),
  },
  {
    value: 'codeOfConduct',
    label: gettextCatalog.getString('Code of conduct'),
  },
  {
    value: 'combinedDeclaration',
    label: gettextCatalog.getString('Combined declaration'),
  },
];
export const documentWhereaboutsTypes = () => [
  { value: 'returned', label: gettextCatalog.getString('Returned') },
  { value: 'destroyed', label: gettextCatalog.getString('Destroyed') },
];
export const courseTypes = () => [
  {
    value: 'basic',
    label: (
      <Space direction="vertical">
        <Typography.Text>
          {gettextCatalog.getString('Basic course')}
        </Typography.Text>
        <Typography.Text type="secondary">
          {gettextCatalog.getString('Includes related refresher course')}
        </Typography.Text>
      </Space>
    ),
  },
  {
    value: 'extended',
    label: (
      <Space direction="vertical">
        <Typography.Text>
          {gettextCatalog.getString('Extended course')}
        </Typography.Text>
        <Typography.Text type="secondary">
          {gettextCatalog.getString('Includes related refresher course')}
        </Typography.Text>
      </Space>
    ),
  },
];

export interface fileInterface {
  id: string;
  url: string;
  title: string;
  response?: {
    uuid: string;
  };
}
export interface documentInterface {
  id: number;
  documentType: string;
  reviewDate: Date | string;
  reviewedBy: string;
  file?: fileInterface[];
}
export interface coursesInterface {
  id?: number;
  courseTitle: string;
  courseType?: string;
  courseDate?: string;
  reviewDate: Date | string;
  reviewedBy: string;
  files?: fileInterface[];
}
export interface certificateOfConductInterface {
  id?: number;
  reviewedBy: string;
  reviewDate: string;
  followupDate: string;
  documentWhereabouts?: string;
}
export interface safeguardingInformationInterface {
  type: string; // employee, volunteer
  workAndResponsibilities: string;
  parishIds: number[];
  safeguardRequirement: string; // not_required, basic, extended
}
export interface MissingDocumentInterface {
  codeOfConduct: boolean;
  selfDisclosure: boolean;
}
export interface MissingCourseInterface {
  basic: boolean;
  extended: boolean;
}
export interface missingDocumentInterface {
  certificateOfConduct: boolean;
  courses: MissingCourseInterface;
  documents: MissingDocumentInterface;
}
export interface SafeguardingListInterface {
  userId: number;
  fullName: string;
  email: string;
  certificateOfConduct?: certificateOfConductInterface;
  course: coursesInterface;
  requirement: string;
  missing: missingDocumentInterface;
}
export interface SafeguardingListQueryInterface {
  canEdit: boolean;
  items: SafeguardingListInterface[];
  cursor: string;
}
export interface SafeguardingDetailsInterface {
  userId: number;
  fullName: string;
  email: string;
  birthdayDate: string;
  pictureUri: string;
  safeguardInfo: safeguardingInformationInterface;
  certificatesOfConduct: certificateOfConductInterface[];
  courses: coursesInterface[];
  documents: documentInterface[];
}
export interface RecordInterface {
  type: string;
  reviewedBy: number;
  reviewDate: moment.Moment;
  // when type is document
  documentType?: string;
  file?: fileInterface[];
  // when type is certificateOfConduct
  followupDate?: moment.Moment;
  documentWhereabouts?: string;
  comments?: string;
  // when file type is course
  courseTitle?: string;
  courseType?: string;
  courseDate?: moment.Moment;
  refreshRequired?: boolean;
  refreshDate?: moment.Moment;
}
